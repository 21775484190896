/* ### DEFAULT VALUES - XS ### */

/* NOTE: Only one arrow can currently be applied at any one time as they all use the :after pseudo class */

.arrow {
}
    .arrow:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
    }
    .arrow.border-STAN, .arrow.border-STAN:after {
        border-color: #3EA736;
    }
    .arrow.border-XTRA, .arrow.border-XTRA:after {
        border-color: #0376BB;
    }
    .arrow.border-PLUS, .arrow.border-PLUS:after {
        border-color: #ffbf00;
    }
    .arrow.border-TELE, .arrow.border-TELE:after {
        border-color: #58c5c7;
    }

    .arrow.hide-arrow-xs::after {
        display: none;
    }

.arrow-right {
    border-right-style: solid;
    border-right-width: 5px;
}

    .arrow-right:after {
        right: -15px;
        left: auto;
        border-left-style: solid;
        border-left-width: 15px;
        border-top: 20px solid transparent !important;
        border-bottom: 20px solid transparent !important;
    }


.arrow-bottom {
    border-bottom-style: solid;
    border-bottom-width: 5px;
}
    .arrow-bottom:after {
        top: auto;
        bottom: -17px;
        border-top-style: solid;
        border-top-width: 15px;
        border-left: 20px solid transparent !important;
        border-right: 20px solid transparent !important;
    }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
    .arrow.hide-arrow-sm::after {
        display: none;
    }
    .arrow.show-arrow-sm::after {
        display: inline;
    }
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .arrow.hide-arrow-md::after {
        display: none;
    }
    .arrow.show-arrow-md::after {
        display: inline;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .arrow.hide-arrow-lg::after {
        display: none;
    }
    .arrow.show-arrow-lg::after {
        display: inline;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
    .arrow.hide-arrow-xl::after {
        display: none;
    }

    .arrow.show-arrow-xl::after {
        display: inline;
    }
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
    .arrow.hide-arrow-xxl::after {
        display: none;
    }

    .arrow.show-arrow-xxl::after {
        display: inline;
    }
}
