/* ### DEFAULT VALUES - XS ### */

.border-shadow,
.border-shadow:focus,
.border-shadow:active {
    box-shadow: 2px 2px 2px 0px #999;
}

.border-2 > .card-header {
    border-radius: calc(0.25rem - 3px) calc(0.25rem - 3px) 0 0
}

.border-solid {
    border-style: solid;
}

.bt-solid {
    border-top-style: solid;
}

.br-solid {
    border-right-style: solid;
}

.bb-solid {
    border-bottom-style: solid;
}

.bl-solid {
    border-left-style: solid;
}

.border-rad-0 {
    border-radius: 0;
}


/* BORDER TOP */

.bt-0 {
    border-top-width: 0;
}

.bt-1 {
    border-top-width: 1px;
}

.bt-5 {
    border-top-width: 5px;
}

/* BORDER RIGHT */

.br-0 {
    border-right-width: 0;
}

.br-1 {
    border-right-width: 1px;
}

.br-5 {
    border-right-width: 5px;
}

/* BORDER BOTTOM */

.bb-0 {
    border-bottom-width: 0;
}
.bb-1 {
    border-bottom-width: 1px;
}

.bb-5 {
    border-bottom-width: 5px;
}

/* BORDER LEFT */

.bl-0 {
    border-left-width: 0;
}

.bl-1 {
    border-left-width: 1px;
}

.bl-5 {
    border-left-width: 5px;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {

    /* BORDER RADIUS */

    .border-rad-sm-1 {
        border-radius: 0.25rem;
    }

    /* BORDER TOP */

    .bt-sm-0 {
        border-top-width: 0;
    }

    .bt-sm-1 {
        border-top-width: 1px;
    }

    .bt-sm-5 {
        border-top-width: 5px;
    }

    /* BORDER RIGHT */

    .br-sm-0 {
        border-right-width: 0;
    }

    .br-sm-1 {
        border-right-width: 1px;
    }

    .br-sm-5 {
        border-right-width: 5px;
    }

    /* BORDER BOTTOM */

    .bb-sm-0 {
        border-bottom-width: 0;
    }

    .bb-sm-1 {
        border-bottom-width: 1px;
    }

    .bb-sm-5 {
        border-bottom-width: 5px;
    }

    /* BORDER LEFT */

    .bl-sm-0 {
        border-left-width: 0;
    }

    .bl-sm-1 {
        border-left-width: 1px;
    }

    .bl-sm-5 {
        border-left-width: 5px;
    }
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

    /* BORDER RADIUS */

    .border-rad-md-1 {
        border-radius: 0.25rem;
    }

    /* BORDER TOP */

    .bt-md-0 {
        border-top-width: 0;
    }

    .bt-md-1 {
        border-top-width: 1px;
    }

    .bt-md-5 {
        border-top-width: 5px;
    }

    /* BORDER RIGHT */

    .br-md-0 {
        border-right-width: 0;
    }

    .br-md-1 {
        border-right-width: 1px;
    }

    .br-md-5 {
        border-right-width: 5px;
    }

    /* BORDER BOTTOM */

    .bb-md-0 {
        border-bottom-width: 0;
    }

    .bb-md-1 {
        border-bottom-width: 1px;
    }

    .bb-md-5 {
        border-bottom-width: 5px;
    }

    /* BORDER LEFT */

    .bl-md-0 {
        border-left-width: 0;
    }

    .bl-md-1 {
        border-left-width: 1px;
    }

    .bl-md-5 {
        border-left-width: 5px;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

    /* BORDER RADIUS */

    .border-rad-lg-1 {
        border-radius: 0.25rem;
    }

    /* BORDER TOP */

    .bt-lg-0 {
        border-top-width: 0;
    }

    .bt-lg-1 {
        border-top-width: 1px;
    }

    .bt-lg-5 {
        border-top-width: 5px;
    }

    /* BORDER RIGHT */

    .br-lg-0 {
        border-right-width: 0;
    }

    .br-lg-1 {
        border-right-width: 1px;
    }

    .br-lg-5 {
        border-right-width: 5px;
    }

    /* BORDER BOTTOM */

    .bb-lg-0 {
        border-bottom-width: 0;
    }

    .bb-lg-1 {
        border-bottom-width: 1px;
    }

    .bb-lg-5 {
        border-bottom-width: 5px;
    }

    /* BORDER LEFT */

    .bl-lg-0 {
        border-left-width: 0;
    }

    .bl-lg-1 {
        border-left-width: 1px;
    }

    .bl-lg-5 {
        border-left-width: 5px;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {

    /* BORDER RADIUS */

    .border-rad-xl-1 {
        border-radius: 0.25rem;
    }

    /* BORDER TOP */

    .bt-xl-0 {
        border-top-width: 0;
    }

    .bt-xl-1 {
        border-top-width: 1px;
    }

    .bt-xl-5 {
        border-top-width: 5px;
    }

    /* BORDER RIGHT */

    .br-xl-0 {
        border-right-width: 0;
    }

    .br-xl-1 {
        border-right-width: 1px;
    }

    .br-xl-5 {
        border-right-width: 5px;
    }

    /* BORDER BOTTOM */

    .bb-xl-0 {
        border-bottom-width: 0;
    }

    .bb-xl-1 {
        border-bottom-width: 1px;
    }

    .bb-xl-5 {
        border-bottom-width: 5px;
    }

    /* BORDER LEFT */

    .bl-xl-0 {
        border-left-width: 0;
    }

    .bl-xl-1 {
        border-left-width: 1px;
    }

    .bl-xl-5 {
        border-left-width: 5px;
    }
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {

    /* BORDER RADIUS */

    .border-rad-xxl-1 {
        border-radius: 0.25rem;
    }

    /* BORDER TOP */

    .bt-xxl-0 {
        border-top-width: 0;
    }

    .bt-xxl-1 {
        border-top-width: 1px;
    }

    .bt-xxl-5 {
        border-top-width: 5px;
    }

    /* BORDER RIGHT */

    .br-xxl-0 {
        border-right-width: 0;
    }

    .br-xxl-1 {
        border-right-width: 1px;
    }

    .br-xxl-5 {
        border-right-width: 5px;
    }

    /* BORDER BOTTOM */

    .bb-xxl-0 {
        border-bottom-width: 0;
    }

    .bb-xxl-1 {
        border-bottom-width: 1px;
    }

    .bb-xxl-5 {
        border-bottom-width: 5px;
    }

    /* BORDER LEFT */

    .bl-xxl-0 {
        border-left-width: 0;
    }

    .bl-xxl-1 {
        border-left-width: 1px;
    }

    .bl-xxl-5 {
        border-left-width: 5px;
    }
}
