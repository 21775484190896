/* ### DEFAULT VALUES - XS ### */

a {
    text-decoration: none;
    color: #7975be;
}
    a:hover {
        text-decoration: underline;
        color: #7975be;
    }

    a.white-link {
        color: white;
    }

        a.white-link:hover {
            text-decoration: none;
            color: #ffe6be;
        }

    a.black-link {
        color: black;
    }

        a.black-link:hover {
            text-decoration: none;
            color: #c5b4e3;
        }

.ls-2 {
    letter-spacing: 2px;
}

.fs-80 {
    font-size: 0.8rem;
}

.fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
    .fs-sm-5 {
        font-size: 1.25rem !important;
    }

    .fs-sm-4 {
        font-size: 1.5rem !important;
    }
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .fs-md-5 {
        font-size: 1.25rem !important;
    }

    .fs-md-4 {
        font-size: 1.5rem !important;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .fs-lg-5 {
        font-size: 1.25rem !important;
    }

    .fs-lg-4 {
        font-size: 1.5rem !important;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
    .fs-xl-5 {
        font-size: 1.25rem !important;
    }

    .fs-xl-4 {
        font-size: 1.5rem !important;
    }
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
    .fs-xxl-5 {
        font-size: 1.25rem !important;
    }

    .fs-xxl-4 {
        font-size: 1.5rem !important;
    }
}
