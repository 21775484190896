/* ### DEFAULT VALUES - XS ### */

.layout-background-banner {
}

.layout-background-banner .layout-header {
  color: white;
  padding-top: 1rem;
}

.layout-background-banner .layout-header .navbar .nav-link {
  color: white;
  border-color: white;
}

.layout-background-banner .layout-header .navbar .nav-link:hover {
  color: black;
  border-color: #fc9682;
}

.layout-background-banner .layout-header .navbar .nav-link.active {
  color: white;
  border-color: white;
}
.layout-background-banner .layout-header .navbar .nav-link.active:hover {
  color: black;
  border-color: #fc9682;
}

.layout-background-banner .layout-header .contact-details {
  color: white;
}

.layout-background-banner .layout-header .contact-details .tel-link {
  color: white;
}

.layout-background-banner .layout-header .contact-details .tel-link:hover {
  color: #ffe6be;
}

/* NOTE:  the below contains "not(.header-sticky)" to make it AS selective as the base page.css*/
.layout-background-banner .header-container:not(.header-container-sticky) {
  color: white;
  padding-top: 0;
  padding-bottom: 0;
  border-top: none;
}

.layout-background-banner .layout-main {
  /*padding-top: 0.5rem;*/
}
.layout-background-banner .layout-main .layout-page .body-container {
  padding-top: 0;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
