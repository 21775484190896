/* ### DEFAULT VALUES - XS ### */

.layout-max-width {
  max-width: 1200px;
}

/* .layout-main {
    border-top: 3px solid #ffc629;
    padding-top: 2rem;
} */

    /* Add a top margin where there is no progress bar */
    .layout-main > .row:first-child:not(.site-progress) {
        padding-top: 3rem; /* was a margin, but doesn't work here as there's now a background colour */
    }

    .layout-main .site-progress {
        padding-top: 0.5rem;
    }

        .layout-main .site-progress .MuiPaper-root {
            /* remove the MUI background colour */
            background-color: transparent;
        }

    /* NB: See site-alerts.css for site alert styling */
    .layout-main .site-alerts {
        margin-bottom: 2rem;
    }


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
