/* ### DEFAULT VALUES - XS ### */

.animation-element.slider {
	opacity: 0;
	position: relative;
	-moz-transition: all 750ms linear;
	-webkit-transition: all 750ms linear;
	-o-transition: all 750ms linear;
	transition: all 750ms linear;
}

.animation-element.slider.slide-left {
	-moz-transform: translateX(-50px);
	-webkit-transform: translateX(-50px);
	-o-transform: translate(-50px, 0px);
	-ms-transform: translate(-50px, 0px);
	transform: translateX(-50px);
}

.animation-element.slider.slide-left.in-view {
	opacity: 1;
	-moz-transform: translate3d(0px, 0px, 0px);
	-webkit-transform: translate3d(0px, 0px, 0px);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate3d(0px, 0px, 0px);
}

.animation-element.slider.slide-right {
	-moz-transform: translateX(100px);
	-webkit-transform: translateX(100px);
	-o-transform: translate(100px, 0px);
	-ms-transform: translate(100px, 0px);
	transform: translateX(100px);
}

.animation-element.slider.slide-right.in-view {
	opacity: 1;
	-moz-transform: translate3d(0px, 0px, 0px);
	-webkit-transform: translate3d(0px, 0px, 0px);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate3d(0px, 0px, 0px);
}

.animation-element.slider.slide-down {
	-moz-transform: translateY(-50px);
	-webkit-transform: translateY(-50px);
	-o-transform: translate(0px, -50px);
	-ms-transform: translate(0px, -50px);
	transform: translateY(-50px);
	max-height: 0;
	transition: max-height 0.15s ease-out;
	overflow: hidden;
}

.animation-element.slider.slide-down.in-view {
	opacity: 1;
	-moz-transform: translate3d(0px, 0px, 0px);
	-webkit-transform: translate3d(0px, 0px, 0px);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate3d(0px, 0px, 0px);
	max-height: 200px;
	transition: max-height 0.35s ease-in;
}

.animation-element.fader {
	opacity: 0;
	-moz-transition: opacity 0.4s ease-in-out;
	-o-transition: opacity 0.4s ease-in-out;
	-webkit-transition: opacity 0.4s ease-in-out;
	transition: opacity 0.4s ease-in-out;
}

.animation-element.fader.in-view {
	opacity: 1;
}

.animation-element.unroll {
	opacity: 0;
	overflow: hidden;
	-moz-transition: all 1250ms linear;
	-webkit-transition: all 1250ms linear;
	-o-transition: all 1250ms linear;
	transition: all 1250ms linear;
}

.animation-element.unroll.in-view {
	opacity: 1;
	-moz-transform: translateY(0px);
	-webkit-transform: translateY(0px);
	-o-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translateY(0px);
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
