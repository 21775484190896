

.layout-page-mta .mta-change .mta-set {
  border: 1px solid #666;
  padding: 1rem;
  border-radius: 1rem;
}

.layout-page-mta.mta-change .mta-set .mta-set-missing-msg {
  /* color: green; */
}

.layout-page-mta.mta-change .mta-set .mta-list-item {
  /* border: 1px solid #999;
  padding: 16px;
  border-radius: 3px; */
}

.layout-page-mta.mta-change .mta-set .mta-set-button-container {
  /* border: 1px solid #666;
            padding: 1rem;
            border-radius: 1rem; */
}

.layout-page-mta.mta-change .mta-button-container {
}

.layout-page-mta.mta-change .mta-heading {
  /* font-weight: 700; */
  /* font-size: 1.25rem; */
  text-transform: uppercase;
}

.layout-page-mta.mta-change
  .mta-item-additional-questions
  .mta-item-additional-questions-heading {
  font-weight: 600;
  font-size: 1rem;
  color: #292e39;
}

.layout-page-mta .mta-change-list .mta-change-item-container {
  padding-bottom: 1rem;
}

.layout-page-mta .mta-change-list .mta-change-item-container:not(:first-child) {
  padding-top: 1rem;
}

.layout-page-mta .mta-change-list .mta-change-item-container:not(:last-child) {
  border-bottom: 1px solid #999;
}

.layout-page-mta .mta-change-item-box {
  border: 2px solid #7975be;
  border-radius: 1rem;
}
