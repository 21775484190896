/* ### DEFAULT VALUES - XS ### */
button + .feedback {
    margin-top: 1rem;
}

.feedback a {
    display: inline;
    padding: 0;
}
.feedback.feedback-error a {
    color:  var(--bs-danger-text-emphasis);
    font-weight: 400;
}

.animated-pulse span {
    background-color: white;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
