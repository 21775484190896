/* .input-group-addon i {
  margin-left: -30px;
  cursor: pointer;
  z-index: 200;
  position: absolute;
  font-size: large;
  color: #6c757d;
} */

.input-group .input-group-joined-start,
.input-group .input-group-joined-start:focus,
.input-group .input-group-joined-start:focus-within {
  border-right: none;
  outline: none;
  box-shadow: none !important;
}

.input-group .input-group-joined-end {
  border-left: none;
  background-color: transparent;
}

.form-text * {
  font-size: inherit;
}
/* .input-group .input-group-joined-start.has-error,
.input-group .input-group-joined-end.has-error {
  border-color: red;
} */

.page-link {
  cursor: pointer;
}

strong {
  font-weight: 500;
}