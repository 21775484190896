/* ### DEFAULT VALUES - XS ### */

.z-index-100 {
	z-index: 100;
}

.mt-n1 {
	margin-top: -1rem;
}

.mt-n2 {
	margin-top: -1.25rem;
}

ls-1 {
	letter-spacing: 2px;
}

.z-index-max {
	z-index: 99999;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
