/* ### DEFAULT VALUES - XS ### */

.layout-footer .payment-methods-container img {
	max-height: 30px;
}

.layout-footer div.section-break,
.layout-footer br.section-break {
	height: 6.5rem;
}

.layout-footer .brand-img {
	height: 29.41px;
	width: 110px;
}

.layout-footer .footer-links a {
	display: block;
	font-weight: bold;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {

	.layout-footer .brand-img {
		height: 50.81px;
		width: 190px;
	}

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
	.layout-footer .max-w-xxl {
		max-width: 1600px;
	}
}
