/* ### DEFAULT VALUES - XS ### */

.layout-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: white;
}

    .layout-header > .row {
        min-height: 80px;
    }

    .layout-header .brand-img {
        height: 45px;
    }

    .layout-header .nav-link {
        text-align: right;
    }

        .layout-header .nav-link:hover {
            background-color: #FC9682;
            border-color: #FC9682;
        }
        .layout-header .nav-link.active:hover {
            background-color: transparent;
        }

    .layout-header .contact-details {
        color: black;
        font-size: 1.2rem;
        padding-top: 0.5rem;
    }

        .layout-header .contact-details .tel-link {
            color: black;
        }

            .layout-header .contact-details .tel-link:hover {
                text-decoration: none;
                color: #c5b4e3;
            }
    


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {

    .layout-header {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

        .layout-header > .row {
            min-height: 90px;
        }

        .layout-header .brand-img {
            height: 50.81px;
            width: 190px;
        }

        .layout-header .navbar-expand-md .nav-link,
        .layout-header .flex-md-row .nav-link {
            color: black;
            border: 2px solid black;
            border-radius: 50px;
            padding: 10px 1rem;
            line-height: 26px;
            cursor: pointer;
            white-space: nowrap;
            font-weight: 600;
            text-decoration: none;
        }
            .layout-header .navbar-expand-md .nav-link:not(:last-child),
            .layout-header .flex-md-row .nav-link:not(:last-child) {
                margin-right: 0.5rem;
            }

        .layout-header .navbar-expand-md .navbar-nav .nav-link {
            min-width: 100px;
            text-align: center;
        }

        .layout-header .contact-details {
            font-size: 1.1rem;
            padding-top: 0;
        }

        .layout-header .nav-link.active:hover {
            background-color: #FC9682;
        }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    .layout-header .navbar-expand-lg .navbar-nav .nav-link {
        color: black;
        border: 2px solid black;
        border-radius: 50px;
        padding: 10px 1rem;
        line-height: 26px;
        cursor: pointer;
        white-space: nowrap;
        font-weight: 600;
        text-decoration: none;
    }

        .layout-header .nav-link:not(:last-child) {
            margin-right: 0.5rem;
        }

    .layout-header .navbar-nav .nav-link {
        min-width: 100px;
        text-align: center;
    }

    .layout-header .contact-details {
        font-size: 1.2rem;
        padding-top: 0;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
