/* ### DEFAULT VALUES - XS ### */

.policy-details {}

.policy-details .policy-details-heading {
    font-weight: 700;
    border-bottom: 2px solid #ffc629;
    padding-bottom: 0.25rem;
    margin-bottom: 1.25rem;
}

.policy-details .policy-details-content {
    margin-bottom: 1rem;
}

.policy-details .display-item {
    margin-bottom: 0.25rem;
}

.policy-details .display-item.car-reg {
    margin-bottom: 0.5rem;
}

.policy-details .data-label {
    color: #7975be;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
}

.policy-details .data-label:not(.hz-mb) {
    margin-bottom: 0.5rem;
}

.policy-details .data-value {
    overflow-wrap: anywhere;
    font-size: 0.875rem;
}

.policy-details .data-value:not(.hz-mb) {
    margin-bottom: 1rem;
}

.policy-details .data-value:last-child {
    margin-bottom: 0;
}

.policy-details .info-box {
    margin-top: 0;
}

.policy-details .section-heading {
    margin-bottom: 0.75rem;
}

.policy-details .data-value .precis {
    margin-bottom: 0.75rem;
}

.policy-details .data-value .precis:last-child {
    margin-bottom: 0;
}


.policy-details .data-value h4 {
    font-size: 1rem;
}

.policy-details .data-value p {
    margin-bottom: 0.5rem;
}

.policy-details .data-value br {
    display: none;
}

.policy-details .data-value br+p {
    font-weight: bold;
}

.policy-details .data-value .precis-heading {
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.policy-details .data-value .precis-text:not(:last-child) {
    margin-bottom: 0.5rem;
}


/* ### Mobile - SM ### */
@media (min-width: 576px) {}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .policy-details .data-value h4 {
        font-size: 1.1rem;
    }

    .policy-details .data-label {
        font-size: 1rem;
    }

    .policy-details .data-value {
        font-size: 1rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {}