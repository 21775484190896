.header-sticky-active .mta-price-item {
    font-size: 1.25rem;
    font-weight: 700;
}

    .header-sticky-active .mta-price-item .whole {
        font-size: 3.5rem;
        font-weight: 900;
    }
    .header-sticky-active .header-container .header-text {
        font-size: 1.25rem;
        margin-bottom: 0;
        margin-top: 0.75rem;
    }
    .header-sticky-active .mta-price-card .card-footer {
        display: none;
    }

/* ANIMATION */
.header-sticky
.header-container
.mta-price-item.mta-price-item-animated {
    animation: price-standard-start 0.5s forwards;
}

.header-sticky-active
.header-container
.mta-price-item.mta-price-item-animated {
    animation: price-sticky-start 0.5s forwards;
}

/* Hide non-selected price when sticky header is active */
.header-sticky-active .mta-price-container:not(.is-selected),
.header-sticky-active .mta-price-divider,
.header-sticky-active .ipt-statement {
    display: none;
}

@keyframes price-sticky-start {
0% {
    transform: scale(1.1, 1.1);
}

100% {
    transform: scale(1, 1);
}
}

@keyframes price-standard-start {
0% {
    transform: scale(0.8, 0.8);
}

100% {
    transform: scale(1, 1);
}
}

