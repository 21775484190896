/* ### DEFAULT VALUES - XS ### */

/* ==== DOCUMENTS - PANEL ==== */

.page-documents {
}
    .page-documents .vehicle-details .vehicle-registration {
        font-size: 1.7rem;
    }


/* ==== DOCUMENTS - PANEL END ==== */


/* ==== DOCUMENT VIEWER - PAGER ==== */

.documents-pager {
}

    .documents-pager .pagination .page-link {
        border-color: #7975be;
        color: black;
        font-size: 0.8rem;
    }

        .documents-pager .pagination .page-link:hover {
            background-color: #5b588f;
            color: white;
            text-decoration: none;
        }

        .documents-pager .pagination .page-link.active {
            background-color: #7975be;
            color: white;
        }

        .documents-pager .pagination .page-link:not(.button-nav) {
            width: 50px;
            text-align: center;
        }

/* ==== DOCUMENT VIEWER - PAGER END ==== */

/* ==== DOCUMENT VIEWER - LIST ==== */

.documents-list {
}

    .documents-list .documents-list-headers {
    }

    .documents-list .not-viewed > td {
        background-color: #FFC62915;
        font-weight: 600 !important;
    }
    .documents-list .not-viewed span {
        font-weight: 600 !important;
    }    

    .documents-list .documents-list-item {
    }

        .documents-list .documents-list-item:not(:first-child) {
            border-top: 1px solid #ccc;
        }

        

        .documents-list .documents-list-item .doc-dl {
            text-align: left;
        }

        .documents-list .documents-list-item .doc-dl span span {
            margin-left: 1rem;
        }

    .documents-list .documents-list-item td {
        padding-bottom: 0.25rem;
    }

    .documents-list .valid-dates td {
        padding-top: 0;
    }

    .documents-list .documents-list-item-error-row {
    }

/* ==== DOCUMENT VIEWER - LIST END ==== */

/* ==== DOCUMENT VIEWER - FILE VIEWER ==== */

.item-viewer {
    background-color: pink;
}

    .item-viewer
    .item-viewer-pdf {
        background-color: cadetblue;
        padding: 10px;
    }

/* ==== DOCUMENT VIEWER - FILE VIEWER END ==== */

/* ==== DOCUMENT UPlOAD ==== */

.documents-upload {
}

/* ==== DOCUMENT UPlOAD ==== */

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {

    .documents-list .documents-list-item td {
        padding-bottom: 0.5rem;
    }
    .documents-list .date {
        min-width: 130px;
    }

    .doc-upload-card.card-fb > .card-body {
        /* padding-top: 0; */
    }
    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

