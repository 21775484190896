/* ### DEFAULT VALUES - XS ### */

.background-slideshow {
    height: 585px;
    position: relative;
    overflow: hidden;
}
    .background-slideshow ~ .layout-main {
        border-top: none;
    }
    .background-slideshow .layout-header {
        background-color: transparent;
    }

    .background-slideshow .background-overlay,
    .background-slideshow .slideshow-carousel {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
    }


    .background-slideshow .background-overlay {
        background-color: #000;
        opacity: .40;
        transition: background .3s,border-radius .3s,opacity .3s;
        z-index: -1;
    }

    .background-slideshow .slideshow-carousel {
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        z-index: -2;
    }

    .background-slideshow .shaper {
        background-color: transparent;
        overflow: hidden;
        position: absolute;
        left: 0;
        width: 100%;
        line-height: 0;
        direction: ltr;
    }

        .background-slideshow .shaper.shaper-bottom {
            bottom: -1px;
            transform: rotate(180deg);
        }
        .background-slideshow .shaper .shape-fill {
            fill: white;
            transform-origin: center;
            transform: rotateY(0deg);
        }
        .background-slideshow .shaper svg {
            width: calc(125% + 1.3px);
            height: 90px;
        }

    .background-slideshow .carousel-inner {
        height: 100%;
    }

    .background-slideshow .carousel-item {
        width: 100%;
        height: 100%;
        background-position: 50%;
        background-size: cover;
    }

    .background-slideshow .header-container {
        padding-top: 3rem;
        padding-bottom: 3rem;
        color: white;
    }

        .background-slideshow .header-container .header-text,
        .background-slideshow .header-container .header-text span {
            font-weight: 500;
            font-size: 3rem;
            font-family: Kenac;
        }
        .background-slideshow .header-container .header-description {
            font-size: 1.5rem;
        }
        .background-slideshow .header-container .header-description * {
            font-family: Kenac;
        }

.ken-burns {
    transition-property: transform;
    transition-duration: 10s;
    transition-timing-function: linear;
}




/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {

    .background-slideshow {
        height: 685px;
    }

        .background-slideshow .header-container .header-text {
            font-size: 4rem;
        }

        .background-slideshow .header-container .header-description {
            font-size: 2rem;
        }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {

    .background-slideshow .header-container {
        padding-top: 5rem;
    }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
