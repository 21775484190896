.layout-page-mta .error-quote .icon-header > div:first-of-type {
    width: 120px;
}

.layout-page-mta .error-quote .icon {
    font-size: 4rem;
}

.layout-page-mta .error-quote .text {
    font-weight: 700;
    font-size: 2rem;
}
