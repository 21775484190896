/* PAGE NAVIGATION */

.layout-page-mta .btn-mta.btn-fb-loading {
  color: white;
  background-color: black;
  opacity: 1;
}

.layout-page-mta .btn-mta {
  background-color: #7975be;
  border-color: #7975be;
  color: white;
  min-height: 0; /* #2821 */
  /* padding: 1rem 1.5rem; */
  /* font-weight: 500; */
}

.layout-page-mta .btn-mta:active,
.layout-page-mta .btn-mta.active {
  background-color: #5b588f;
  border-color: #7975be;
  color: white;
}

.layout-page-mta .btn-mta:focus {
  background-color: #5b588f;
  border-color: #7975be;
  color: white;
  box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25);
}

.layout-page-mta .btn-mta:hover {
  background-color: #5b588f;
  border-color: #7975be;
  color: white;
}

.layout-page-mta .btn-outline-mta,
.layout-page-mta .btn-check:focus .btn-outline-mta {
  color: #7975be;
  border-color: #7975be;
  background-color: white;
  border-width: 1px; /* #2821 */
  border-style: solid;
}

.layout-page-mta .btn-outline-mta:active,
.layout-page-mta .btn-check:active + .btn-outline-mta,
.layout-page-mta .btn-check:checked + .btn-outline-mta,
.layout-page-mta .btn-check:active + .btn-outline-primary,
.layout-page-mta .btn-outline-mta.active,
.layout-page-mta .btn-outline-mta.dropdown-toggle.show {
  background-color: #7975be;
  border-color: #7975be;
  color: white;
}

.layout-page-mta .btn-check:checked + .btn-outline-mta:focus,
.layout-page-mta .btn-check:active + .btn-outline-mta:focus,
.layout-page-mta .btn-outline-mta:active:focus,
.layout-page-mta .btn-outline-mta.active:focus,
.layout-page-mta .btn-outline-mta.dropdown-toggle.show:focus,
.layout-page-mta .btn-check:focus + .btn-outline-mta,
.layout-page-mta .btn-outline-mta:focus {
  /* box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25); */
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.layout-page-mta .btn-outline-mta:hover {
  background-color: #7975be;
  border-color: #7975be;
  color: white;
}

/*.layout-page-mta .btn-link-fb {
  box-shadow: none !important;
  text-decoration: none;
}
.layout-page-mta .btn-link-fb:hover span {
  text-decoration: underline;
}

.layout-page-mta .btn-link-fb:active span {
  text-decoration: underline;
}

.layout-page-mta .btn-link-fb:focus span {
  text-decoration: underline;
}

.layout-page-mta button.btn:disabled {
  background-color: #333;
  color: white;
}

.layout-page-mta button:disabled:hover {
  color: white;
}

.layout-page-mta .btn-loading {
  min-height: 40px;
  padding: 0 var(--bs-btn-padding-x);
}

.layout-page-mta .btn-link {
  color: #7975be;
  text-decoration: none;*/
/* padding: 0; */
/*}
.layout-page-mta .btn-link:hover {
  color: #7975be;
  text-decoration: underline;
}*/

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
