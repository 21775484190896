.mta-start .panel.form-panel .panel-body {
    padding: 2rem 1rem;
}

.mta-start-section:not(:last-child) {
    padding-bottom: 1.5rem;
}

.mta-start-section.disabled * {
    color: #ccc !important;
}
.mta-start-section.disabled .policy-details-heading {
    border-color: #ccc;
}

.mta-start-section .section-select svg {
    height: 2.5rem;
    width: 2.5rem;
}

.mta-start-section .help-text {
    color: black;
    margin-top: 1rem;
}

.mta-start-section .help-text div {
    margin-bottom: 0.5rem;
}
.mta-start-section li {
    margin-bottom: 0.5rem;
}

.mta-start-section .vehicle-details div:not(:last-child) {
    margin-bottom: 1rem;
}


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .mta-start .panel.form-panel .panel-body {
        padding: 2.25rem 1.75rem; /* Reset for MD */
    }

    .mta-start-section .section-select {
        padding-right: 5rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
