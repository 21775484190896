/* ### DEFAULT VALUES - XS ### */
 .panel {
}

 .panel:not(:last-child) {
  margin-bottom: 3rem;
}

/* The custom heading outside of the box */
 .panel .panel-heading {
  color: black;
  font-size: 1.75rem;
}

 .panel .panel-heading h2 {
  font-weight: 400;
}
 .panel .panel-heading h2 .icon {
  margin-right: 0.25rem;
  min-width: 40px;
  display: inline-block;
  text-align: center;
}

 .panel .panel-heading .small {
  font-size: 1rem;
}

 .panel .panel-heading .vehicle-registration {
  font-size: 1.7rem;
}

 .panel .panel-body {
}

 .panel.form-panel .panel-heading {
  /*margin-bottom: 1.5rem;*/
}

 .panel.form-panel .panel-body {
  border: 1px solid #999;
  border-radius: 10px;
  padding: 2.25rem 1.75rem;
}

 .panel.form-panel .fb-form-group:not(.with-link) {
  padding-bottom: 1.5rem;
}

 .panel.form-panel .fb-form-group.with-link {
  padding-bottom: 0.5rem;
}
 .panel.form-panel .fb-form-group.with-link .fixed-height {
  display: none;
}

 .panel.form-panel .redirect {
  padding-bottom: 1.5rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
  .panel .panel-heading h2 .icon {
    margin-right: 0.25rem;
    min-width: 50px;
    display: inline-block;
  }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
