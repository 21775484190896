.layout-page-mta.mta-quote {
}

.layout-page-mta.mta-quote .body-container {
    padding-top: 1rem;
}

    .mta-price-card {
    }
        .mta-price-card .card-header {
            padding-bottom: 0;
        }
        .mta-price-card .card-footer {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .mta-price .ipt-statement small {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
        }

    .mta-price-item {
        font-weight: 700;
    }

    .mta-price-item {
        font-size: 1.25rem;
        font-weight: 700;
    }
        .mta-price-item span {
            font-weight: 700;
        }
        .mta-price-item .pound {
            font-size: 4.5rem;
            font-weight: 900;
        }

    .ipt-statement {
        padding-top: 1rem;
        margin-top: 1rem;
    }

    .inst-details .price {
        font-size: 1rem;
        font-weight: bold;
    }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
