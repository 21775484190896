/* ### DEFAULT VALUES - XS ### */

.header-sticky-active .renewal-price-container .price {
    font-size: 1.25rem;
    font-weight: 700;
}
.header-sticky-active .renewal-price-container .price .pound {
    font-size: 3.5rem;
    font-weight: 900;
}
.header-sticky-active .renewal-price-container .price-info {
    padding-bottom: 1rem;
}

/* ANIMATION */
.header-sticky .header-container .renewal-price-item.renewal-price-item-animated {
    animation: price-standard-start 0.5s forwards;
}

.header-sticky-active .header-container .renewal-price-item.renewal-price-item-animated {
    animation: price-sticky-start 0.5s forwards;
}

.header-sticky .header-container .renewal-animated {
    animation: price-standard-start 0.5s forwards;
}

.header-sticky-active .header-container .renewal-animated {
    animation: price-sticky-start 0.5s forwards;
}



/* Hide non-selected price when sticky header is active */
.header-sticky-active .header-text,
.header-sticky-active .renewal-price-container:not(.is-selected),
.header-sticky-active .renewal-price-divider,
.header-sticky-active .ipt-statement {
    display: none;
}

@keyframes price-sticky-start {
    0% {
        transform: scale(1.1, 1.1);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes price-standard-start {
    0% {
        transform: scale(0.8, 0.8);
    }

    100% {
        transform: scale(1, 1);
    }
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {}

/* ### Tablet - MD ### */
@media (min-width: 768px) {}

/* ### Desktop - LG ### */
@media (min-width: 992px) {}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {}