/* NOTE: use ".layout-page-mta.layout-page" to make the selector more selective than the base css */

.layout-page-mta.layout-page:not(.mta-start) .layout-max-width {
  max-width: 768px;
}

.layout-page-mta.layout-page:not(.mta-start) .body-container {
  background-color: transparent;
}

.layout-page-mta.layout-page:not(.mta-start) .header-container .header-text {
  text-align: center;
}

.layout-page-mta.layout-page:not(.mta-start) .header-container .description-text {
  text-align: center;
}
