/* NOTE: use ".layout-page-renewal.layout-page" to make the selector more selective than the base css */

.layout-page-renewal.layout-page .layout-max-width {
  max-width: 768px;
}

.layout-page-renewal.layout-page .body-container {
  background-color: transparent;
}

.layout-page-renewal.layout-page .header-container .header-text {
  text-align: center;
}

.layout-page-renewal.layout-page .header-container .description-text {
  text-align: center;
}

.layout-page-renewal.layout-page .header-sticky .header-container-sticky .renewal-price-container .varied-payment .space {
  display: none;
}

.layout-page-renewal.layout-page .header-sticky-active .header-container-sticky .renewal-price-container .varied-payment .space {
  display: inline-block;
}