/* ### DEFAULT VALUES - XS ### */

.policy-viewer {
}

    .policy-viewer .policy-viewer-item {
    }

        .policy-viewer .policy-viewer-item:not(:last-child) {
            margin-bottom: 2rem;
        }

        .policy-viewer .policy-viewer-item > .card-header {
            
        }

            .policy-viewer .policy-viewer-item > .card-header .icon.policy-type-icon {
                font-size: 4rem;
                margin-right: 1rem;
            }

        .policy-viewer .policy-viewer-item > .card-body {
        }

            .policy-viewer .policy-viewer-item > .card-body .pol-detail {
                margin-bottom: 1rem;
                font-size: 0.875rem;
            }

            .policy-viewer .policy-viewer-item > .card-body .fb-heading {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 0.875rem;
            }

        .policy-viewer .policy-viewer-item.inactive > .card-header {
            background-color: #dedede;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .policy-viewer .policy-viewer-item.inactive > .card-body,
        .policy-viewer .policy-viewer-item.inactive > .card-footer {
            background-color: #dedede25;
        }

        .policy-viewer .policy-viewer-item .policy-info {
            border: none;
            border-radius: 10px;
        }

            .policy-viewer .policy-viewer-item .policy-info.renewal {
                background-color: #fff3df;
            }
            .policy-viewer .policy-viewer-item .policy-info.renewal .card-header {
                display: flex;
            }
            .policy-viewer .policy-viewer-item .policy-info.renewal .card-header .icon {
                font-size: 1.5rem;
            }

            .policy-viewer .policy-viewer-item .policy-info.future-events {
                background-color: #fff3df;
            }

            .policy-viewer .policy-viewer-item .policy-info.cancelled {
                background-color: #f8d7da;
            }

            .policy-viewer .policy-viewer-item .policy-info .card-header {
                font-weight: 700;
                font-size: 1rem;
                padding-top: 1rem;
                background-color: transparent;
                border-bottom: none;
            }

            .policy-viewer .policy-viewer-item .policy-info .card-body {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }

            .policy-viewer .policy-viewer-item .policy-info .future-event {
                margin-bottom: 0.5rem;
            }

            .policy-viewer .policy-viewer-item .policy-info .card-footer {
                padding-bottom: 1rem;
                border: none;
                background-color: transparent;
            }

        .policy-viewer .policy-viewer-item > .card-footer {
            padding-bottom: 1rem;
            border-top: none;
        }

            .policy-viewer .policy-viewer-item > .card-footer button {
                width: 100%;
            }

                .policy-viewer .policy-viewer-item > .card-footer button:not(:last-of-type) {
                    margin-bottom: 0.5rem;
                }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
    .policy-viewer .policy-viewer-item > .card-footer {
    }

        .policy-viewer .policy-viewer-item > .card-footer button {
            width: 49%;
            margin-bottom: 0.5rem;
        }

            .policy-viewer .policy-viewer-item > .card-footer button:nth-child(odd) {
                margin-right: 0.5rem;
            }
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .policy-viewer .policy-viewer-item:not(:last-child) {
        margin-bottom: 3rem;
    }

    .policy-viewer .policy-viewer-item > .card-header .icon.policy-type-icon {
        font-size: 5rem;
    }

    .policy-viewer .policy-viewer-item > .card-body {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

        .policy-viewer .policy-viewer-item > .card-body .pol-detail {
            font-size: 1rem;
        }

        .policy-viewer .policy-viewer-item > .card-body .fb-heading {
            font-size: 1rem;
        }

        .policy-viewer .policy-viewer-item > .card-body .pol-detail.order-md-2 {
        }

    .policy-viewer .policy-viewer-item > .card-footer {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
    }

        .policy-viewer .policy-viewer-item > .card-footer button {
            margin-bottom: 0;
            width: auto;
        }

            .policy-viewer .policy-viewer-item > .card-footer button:not(:last-of-type) {
                margin-bottom: 0;
                margin-right: 0.5rem;
            }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
