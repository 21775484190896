/* ### DEFAULT VALUES - XS ### */

/* BORDER COLOURS */

.border-black {
  border-color: #000;
}

.border-purple {
  border-color: #7975be !important; /* Need to use important to override bootstrap defaults */
}
.border-lightgrey {
  border-color: lightgrey;
}

.border-lightgrey {
  border-color: lightgrey;
}

.border-STAN {
  border-color: #3ea736;
}

.border-XTRA {
  border-color: #0376bb;
}

.border-PLUS {
  border-color: #ffbf00;
}

.border-TELE {
  border-color: #58c5c7;
}

/* TEXT COLOURS */

.text-dimgrey {
  color: dimgray;
}

.text-green {
  color: #3ea736 !important;
}

.text-red {
  color: red !important;
}

.text-grey {
  color: #666666 !important;
}

.text-purple {
  color: #7975be;
}

.text-teal {
  color: #17a2b8;
}
.text-yellow-stella {
  color: #fff3df;
}

.text-light-purple {
    color: #c5b4e3;
}

.text-buttermilk {
    color: #ffe6be;
}

/* BUTTON COLOURS */

/* BACKGROUND COLOURS */

.bg-buttermilk {
    background-color: #fff3df;
}

.bg-light-STAN {
  background-color: rgba(57, 162, 68, 0.15);
}

.bg-dark-STAN {
  background-color: #3ea736;
}

.bg-light-XTRA {
  background-color: #0376bb15; /*rgba(186, 186, 186, 0.25);*/
}

.bg-dark-XTRA {
  background-color: #0376bb;
}

.bg-light-PLUS {
  background-color: rgba(255, 191, 2, 0.15);
}

.bg-dark-PLUS {
  background-color: #ffbf00;
}

.bg-light-TELE {
  background-color: rgba(88, 197, 199, 0.25);
}

.bg-dark-TELE {
  background-color: #58c5c7;
}

.bg-purple {
  background-color: #7975be !important;
}

.bg-purple-hover:hover {
  background-color: rgba(58, 5, 138, 0.7) !important;
  color: white !important;
}

.bg-overlay {
  background-color: rgba(102, 102, 102, 0.6);
}

.bg-octarine {
  background: linear-gradient(
    180deg,
    rgba(107, 73, 156, 1) 70%,
    rgba(0, 153, 0, 0.8) 100%
  );
}

.bg-purplefade {
  background: linear-gradient(180deg, #5e2e86, #886daf);
}

.bg-lightgrey {
  background-color: #ededed;
}

.bg-lightgrey2 {
  background-color: #fbfbfb;
}

.bg-black {
  background-color: #000;
}

.bg-green {
  background-color: #3ea736;
}

.bg-darkgreen {
  background-color: #009900;
}
.bg-yellow-stella {
  background-color: #fff3df;
}

/* BOOTSTRAP OVERRIDES */

.table-secondary {
  --bs-table-bg: #ededed;
}

/* OTHER STYLES - Do they belong elsewhere? */

.tick-start:before {
  content: "\2714\fe0e";
  color: #3ea736;
  padding-right: 5px;
}

.cross-start:before {
  content: "\2718\fe0e";
  color: red;
  padding-right: 5px;
}

.icon-tick:before {
  content: "\2714\fe0e";
  font-size: 1.1rem;
}

.list-style-ticks {
  list-style-type: none;
}

.list-style-ticks li:before {
  content: "\2714\fe0e";
  color: #3ea736;
  padding-right: 5px;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
