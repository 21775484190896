/* ### DEFAULT VALUES - XS ### */

.tab-panel {
}
    
    .tab-panel .tab-panel-mobile .accordion-item:last-child,
    .tab-panel .tab-panel-mobile .accordion-item:last-child .accordion-button {
        border-bottom-left-radius: 11px;
        border-bottom-right-radius: 11px;
    }
        .tab-panel .tab-panel-mobile .accordion-item:last-child .accordion-button:not(.collapsed) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    
    .tab-panel .tab-panel-mobile .tab-panel-data {
    }

    .tab-panel .tab-panel-mobile .tab-heading {
    }

    .tab-panel .tab-panel-mobile .accordion-button {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

        .tab-panel .tab-panel-mobile .accordion-button:not(.collapsed) {
            background-color: #dedede;
            color: black;
        }

            .tab-panel .tab-panel-mobile .accordion-button:not(.collapsed)::after {
            }

        .tab-panel .tab-panel-mobile .accordion-button:focus {
        }

    .tab-panel .tab-panel-mobile .accordion-body {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }


    .tab-panel .section-heading {
        font-size: 1.25rem;
        font-weight: 700;
    }

    .tab-panel .data-label {
        color: #7975be;
        font-size: 0.875rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0.75rem
    }

    .tab-panel .data-value {
        margin-bottom: 0.75rem;
        overflow-wrap: anywhere;
        font-size: 0.875rem;
    }

        .tab-panel .data-value:not(.hz-mb) {
            margin-bottom: 1rem;
        }


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .tab-panel .tab-panel-card {
    }

        .tab-panel .tab-panel-card .tab-panel-buttons {
            width: 100%;
            padding: 1rem 0;
        }

            .tab-panel .tab-panel-card .tab-panel-buttons .nav-item .btn:not(.active) {
                color: #999999;
            }

                .tab-panel .tab-panel-card .tab-panel-buttons .nav-item .btn:not(.active):hover {
                    color: black;
                }

            .tab-panel .tab-panel-card .tab-panel-buttons .nav-item:not(:last-child) {
                margin-bottom: 0.5rem;
            }

        .tab-panel .tab-panel-card .tab-panel-data {
        }

    .tab-panel .data-label {
        font-size: 1rem;
    }

    .tab-panel .data-value {
        font-size: 1rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
