/* ### DEFAULT VALUES - XS ### */
/* .fb-form-group .disabled {
  border-color: dimgrey;
  color: dimgrey;
} */

/* div:disabled,
div[disabled] {
  border: 1px solid red;
  background-color: red;
  color: red;
} */

.fb-heading {
  color: #7975be;
  font-size: 1.2rem;
  font-weight: 700;
}

.fb-form-group .fb-form-label {
  font-weight: 700;
  font-size: 1.2rem;
}

/* .fb-form-group .error-text {
  color: red;
} */

.fb-form-group .fixed-height {
  min-height: 1.5em;
}

/* .fb-form-group .help-text {
  color: #7975be;
} */

.fb-form-group.has-helptext label {
  margin-bottom: 0;
}

/* DATE PICKER */

.fb-form-group .date-picker {
  max-width: 215px;
  padding-top: 0;
  padding-bottom: 0;
}

.fb-form-group:not(.has-error) .form-control.date-picker:focus-within {
  border-bottom: 1px solid #ccc;
  box-shadow: none;
}

.fb-form-group .date-picker input {
  border: none;
  padding-left: 0;
  padding-right: 0;
  min-width: 65px;
}

.fb-form-group .date-picker input:focus {
  box-shadow: 0px -3px 3px -2px #7975be inset;
}

.fb-form-group.has-error .form-control.date-picker:focus-within,
.fb-form-group.has-error .date-picker input {
  box-shadow: none;
}

.fb-form-group .date-picker .append-slash {
  position: relative;
}

.fb-form-group .date-picker .append-slash input {
  min-width: 58px;
}

.fb-form-group .date-picker .append-slash:after {
  content: "/";
  font-weight: bold;
  position: absolute;
  z-index: 1;
  top: 6px;
  right: -5px;
}

/* RADIO BUTTONS */
.fb-form-group .fb-marketing-btn {
  min-width: 48%;
  border-radius: 1rem;
  border-width: 2px;
  margin-right: 0;
  margin-bottom: 0.5rem;
}

.fb-form-group .fb-radio-btn {
  color: #000;
  border-color: #ccc;
}

.fb-form-group .fb-radio-btn:hover {
  color: #fff;
  background-color: #5b588f;
}

.fb-form-group .btn-check:focus + .fb-radio-btn,
.fb-form-group .fb-radio-btn:focus,
.fb-form-group .fb-radio-btn:focus-within {
  border-color: #7975be;
  box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25);
  z-index: 100;
}

.fb-radio-container label {
  flex: 0 0 auto;
  width: 45%;
}

.fb-radio-container label.space-buttons {
  margin-right: 0.5rem;
}

.fb-radio-container.yes-no {
  max-width: 80%;
}

/* TEXT / SELECT */
.fb-form-group .form-control {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.fb-form-group:not(.has-error) .form-control:focus {
  box-shadow: 0px -3px 3px -2px #7975be inset;
}

.fb-form-group .input-group:focus-within {
  border-radius: 0.375rem;
  box-shadow: none;
}

.fb-form-group:not(.has-error) .input-group:focus-within input {
  box-shadow: 0px -3px 3px -2px #7975be inset;
}

/* SEARCH SELECT */

.fb-select div[class*="-control"] {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: none;
}

.fb-select div[class*="-control"]:hover {
  border-color: #ccc;
  box-shadow: none;
}

.fb-select:not(.has-error) div[class*="-control"]:focus-within {
  border-color: #ccc;
  box-shadow: 0px -3px 3px -2px #7975be inset;
}

.fb-select svg {
  color: #7975be;
}

/* PAGE NAVIGATION */
.page-navigation-header {
  margin-bottom: 20px;
}

.page-navigation .btn {
  min-height: 40px;
  padding: 0 var(--bs-btn-padding-x);
  font-size: 1.125rem;
}

.fb-form-group .input-group-status {
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* VALIDATION */
.fb-form-group.is-validated .input-group-status {
  color: green;
}

.fb-form-group.is-validated label:not(.btn) {
  /* color: green; */
}

.fb-form-group.is-validated .form-control,
.fb-form-group.is-validated div[class*="-control"] {
  /* border-color: green; */
  /* padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); */
}

/* ERRORS */
.fb-form-group.has-error label:not(.btn) {
  color: red;
}

.fb-form-group.has-error .form-control {
  border-color: red;
  /* padding-right: calc(1.5em + 0.75rem); */
  /* disabled image. If enabling, need to check the datepicker control	 */
  /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); */
}

.fb-form-group.has-error .input-group-status {
  border-color: red;
  color: red;
}

.fb-form-group.has-error .form-control:focus {
  box-shadow: none;
}

.has-error div[class*="-control"] {
  border-color: red;
  /* padding-right: calc(1.5em + 0.75rem); */
  /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); */
}

.fb-form-group.has-error .fb-radio-btn {
  border-color: red;
}

.has-error .fb-select svg {
  color: red;
}

.has-error .fb-basic-select {
  border-color: red;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
  .fb-radio-container label:not(.space-buttons) {
    width: auto;
  }
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
  /* .fb-form-group .fb-formlabel {
		font-size: 1.2rem;
	} */

  .fb-radio-container label {
    width: auto;
  }

  .fb-form-group .fb-marketing-btn {
    margin-bottom: 0;
    width: 24%;
    min-width: 120px;
  }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
