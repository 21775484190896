/* ### DEFAULT VALUES - XS ### */
.layout-page-mta .datepicker-with-buttons .datepicker-date-display {
  margin-top: 1.2rem;
  font-weight: bold;
}

.layout-page-mta .datepicker-with-buttons .btn {
  padding: 1rem;
}

.layout-page-mta .datepicker-with-buttons .btn-outline-mta {
  color: #000;
  border-color: #ccc;
}

.layout-page-mta .datepicker-with-buttons .btn-outline-mta:hover {
  color: #fff;
  background-color: #5b588f;
}

.layout-page-mta .datepicker-with-buttons .btn-check:focus + .btn-outline-mta,
.layout-page-mta .datepicker-with-buttons .btn-outline-mta:focus,
.layout-page-mta .datepicker-with-buttons .btn-outline-mta:focus-within {
  border-color: #7975be;
  box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25);
  z-index: 100;
}

/* On the QUOTE PAGE, the CANCEL and UPDATE buttons */
.layout-page-mta .row.fb-datepicker-quote-buttons {
  width: 295px;
  margin-left: 12px;
  margin-right: 12px;
}

.layout-page-mta .fb-datepicker {
  padding-top: 1.25rem;
  /* The MUI component expands to 100% of this */
  max-width: 320px; /* The MUI calendar has a min-width of 320px */
}

/* Hide the label within the control */
.layout-page-mta .fb-datepicker label,
.layout-page-mta .fb-datepicker legend {
  display: none;
}

/* The container for the calendar picker  */
.layout-page-mta .fb-datepicker .MuiPickersLayout-contentWrapper {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.375rem;
}
.layout-page-mta .fb-datepicker .MuiPickersLayout-contentWrapper > div {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.layout-page-mta .fb-datepicker .MuiPickersLayout-root {
  max-width: 100%;
  overflow: visible;
}

.layout-page-mta
  .fb-datepicker
  .MuiPickersLayout-contentWrapper
  .MuiPickersToolbar-content {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  max-width: 100%;
}
.layout-page-mta
  .fb-datepicker
  .MuiPickersLayout-contentWrapper
  .MuiPickersToolbar-content
  .MuiDatePickerToolbar-title {
  margin: 0;
}

/* The expand button by the display header */
.layout-page-mta
  .fb-datepicker
  .MuiDateCalendar-root
  div[role="presentation"]
  > button {
  display: none;
}

.layout-page-mta
  .fb-datepicker
  .MuiPickersToolbar-root
  .MuiTypography-overline {
  display: none;
}

.layout-page-mta
  .fb-datepicker
  .MuiPickersToolbar-root
  .MuiPickersToolbar-content
  button {
  display: none;
}

.layout-page-mta
  .fb-datepicker
  .MuiPickersToolbar-root
  .MuiPickersToolbar-content
  h4 {
  font-size: 1.2rem;
  color: #7975be;
}

.layout-page-mta .fb-datepicker
  .MuiDateCalendar-root
  /* .PrivatePickersFadeTransitionGroup-root */
  .Mui-selected {
  background-color: #fdc718;
}

.layout-page-mta .fb-datepicker
  .MuiDateCalendar-root
  /* .PrivatePickersFadeTransitionGroup-root */
  .Mui-selected:hover {
  background-color: #fed34a;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
