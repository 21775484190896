/* ### DEFAULT VALUES - XS ### */
.layout-page.disabled {
}

    .layout-page.disabled .radio-toggle {
        border-color: dimgrey;
        color: dimgrey;
    }

        .layout-page.disabled .radio-toggle:hover {
            background-color: white;
            color: dimgrey;
        }

        .layout-page.disabled .radio-toggle:focus-within,
        .layout-page.disabled .radio-toggle .form-check-input:focus {
            box-shadow: none;
        }

        .layout-page.disabled .radio-toggle .form-check-input:checked {
            background-color: dimgrey;
            border-color: dimgrey;
        }

    .layout-page.disabled .btn-outline-fb {
        border-color: dimgrey;
        color: dimgrey;
    }

        .layout-page.disabled .btn-outline-fb:hover {
            background-color: white;
            color: dimgrey;
        }

        .layout-page.disabled .btn-outline-fb:active,
        .layout-page.disabled .btn-check:checked + .btn-outline-fb,
        .layout-page.disabled .btn-check:active + .btn-outline-primary,
        .layout-page.disabled .btn-outline-fb.active,
        .layout-page.disabled .btn-outline-fb.dropdown-toggle.show {
            background-color: dimgrey;
            color: white;
            box-shadow: none;
        }
    .layout-page.disabled .btn-check:focus + .btn-outline-fb {
        box-shadow: none;
    }

    .layout-page.disabled .btn-fb,
    .layout-page.disabled .btn-fb:focus {
        border-color: dimgrey;
        background-color: dimgrey;
        color: white;
        box-shadow: none;
    }

        .layout-page.disabled .btn-fb:hover {
            background-color: dimgrey;
        }


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
