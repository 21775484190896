/* ### DEFAULT VALUES - XS ### */
.layout-page-mta .address-lookup .btn-search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.layout-page-mta .address-lookup .address-found .address {
  border: #ccc 1px solid;
  border-radius: 5px;
  padding: 16px;
}

.layout-page-mta .address-lookup .address-found .btn-link {
  padding-bottom: 0;
  padding-top: 0;
}

.layout-page-mta .address-lookup .address-heading {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  color: #666;
}

.layout-page-mta .address-lookup .btn-link {
  /* text-decoration: none; */
}

.layout-page-mta .address-lookup .btn-link:hover {
  /* text-decoration: underline; */
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
  .layout-page-mta .address-lookup div.py-3:first-child:not(.fb-form-group) {
    padding: 1rem 0 !important;
  }

  .layout-page-mta .address-lookup .btn-search {
    width: 200px;
  }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
