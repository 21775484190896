/* ### DEFAULT VALUES - XS ### */

.error-page {
}

    .error-page .header-text {
    }

        .error-page .header-text .icon-header > div:first-of-type {
            width: 120px;
        }

        .error-page .header-text .icon {
            font-size: 5rem;
        }
        .error-page .header-text .text {
            font-weight: bold;
        }

    .error-page.layout-page .body-container {
        background-color: transparent;
        padding-top: 0;
    }

    .error-page .card > .card-body {
        padding: 2rem;
        font-size: 1.1rem;
    }
    .error-page .card > .card-body > div:not(:last-child) {
        margin-bottom: 1.5rem;
    }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
