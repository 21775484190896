/* ### DEFAULT VALUES - XS ### */

.file-upload {
}

.file-upload:focus-visible {
  outline: 1px dashed #ddd;
}

.file-upload .file-upload-drag-area {
  border: 1px solid transparent;
  padding-bottom: 1rem;
}

.file-upload .file-upload-drag-area:focus-visible {
  outline: 1px dashed #ddd;
}

.file-upload .file-upload-drag-area.file-upload-drag-area-active .card-fb {
  background-color: #ffc62915;
}

.file-upload .file-upload-errors {
}

.file-upload .file-upload-drop-area {
}

.file-upload-list {
}

.file-upload-list-item {
}

.file-upload-list-item .bi-exclamation {
  font-size: 2rem;
  margin-top: -11px;
}

.file-upload-list-item .file-upload-list-item-preview {
  max-height: 4rem;
}

.file-upload-list-item .file-upload-list-item-preview .react-pdf__Page__canvas {
  /* overflow: hidden; */
}

.doc-type-xs:not(:has(~ .upload-error)) td {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
}

.upload-error td {
  border-bottom: 1px solid #ddd;
}

.upload-error .bi-exclamation {
  font-size: 1.5rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
  .file-upload .file-upload-drag-area {
    /* padding-top: 1rem; */
  }

  .file-upload-list-item:not(:first-child):not(:has(~ .upload-error)) {
    border-top: 1px solid #ddd;
  }

  .file-upload-list-item:not(:first-child):not(:has(~ .upload-error)) td {
  }

  .file-upload-list-item .doc-type {
    width: 250px;
  }

  .file-upload-list .remove {
    width: 70px;
    padding-left: 0;
    padding-right: 0;
  }

  .file-upload-list .remove + * {
    padding-left: 1rem;
  }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
