@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,600,700,900");

@font-face {
  font-family: "Charles Wright";
  src: url("../fonts/CharlesWright-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "Kenac";
    src: url("../fonts/Kenac Medium.otf") format("truetype");
}

// ** apply the font to the entire site **
// https://fonts.google.com/specimen/Montserrat?sidebar.open=true&selection.family=Montserrat
* {
  font-family: Poppins, Roboto, sans-serif;
  font-weight: 300;
}

body {
  font-family: Poppins, Roboto, sans-serif;
  font-weight: 300;
}

// Hide the password REVEAL button
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
