/* ### DEFAULT VALUES - XS ### */

.layout-page-mta .card-row-1 .card-col {
  margin-bottom: 1rem;
  padding-left: 0;
  padding-right: 0;
}

.layout-page-mta .card-highlight {
  border-color: #198754; /*#7975be;*/
}

.layout-page-mta .card-highlight .card-header {
  background-color: #198754; /*#7975be;*/
  color: white !important;
}

.layout-page-mta .card-shadow {
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 50%);
}

.layout-page-mta .align-items-stretch .card {
  flex: 1;
}

.layout-page-mta .card .card-footer a,
.card .quote-buttons a {
  margin: 0.25rem;
}

.layout-page-mta .card.clickable:hover,
.layout-page-mta .card:hover .card-body.clickable,
.layout-page-mta .card .card-footer.clickable:hover {
  cursor: pointer;
}

.layout-page-mta .card.policy-type-card .card-header,
.layout-page-mta .card.opex-card .card-header {
  background-color: white;
  border-bottom: none;
}

.layout-page-mta .card .card-footer {
  background-color: white;
  border-top: none;
}

.layout-page-mta .policy-type-card .title-img {
  max-height: 25px;
}

/* CAR: #0D6EFD - 13,110,253 */
.layout-page-mta .policy-type-card.policy-type-car:hover .card-body.clickable,
.layout-page-mta .policy-type-card.policy-type-car .card-footer.clickable:hover,
.layout-page-mta .policy-type-card.policy-type-car:hover .rounded-icon span {
  /*background-color: rgb(13 110 253 / 15%);*/
}

.layout-page-mta .policy-type-card.policy-type-car:hover .icon:not(.icon-tick) {
  color: rgb(13 110 253 / 100%);
}

/* TELEMATICS: #58C5C7 - 88,197,199 */
.layout-page-mta .policy-type-card.policy-type-tele:hover .card-body.clickable,
.layout-page-mta
  .policy-type-card.policy-type-tele
  .card-footer.clickable:hover,
.layout-page-mta .policy-type-card.policy-type-tele:hover .rounded-icon span {
  /*background-color: rgb(88 197 199 / 18%);*/
}

.layout-page-mta
  .policy-type-card.policy-type-tele:hover
  .icon:not(.icon-tick) {
  /*color: rgb(88 197 199 / 100%);*/
  color: #549293;
}

/* VAN: #646464 - 100,100,100 */
.layout-page-mta .policy-type-card.policy-type-van:hover .card-body.clickable,
.layout-page-mta .policy-type-card.policy-type-van .card-footer.clickable:hover,
.layout-page-mta .policy-type-card.policy-type-van:hover .rounded-icon span {
  /* background-color: rgb(100 100 100 / 15%);*/
}

.layout-page-mta .card.policy-type-card:hover .icon-policytype-van {
  color: rgb(0 0 0 / 100%);
}
/* HOME: #? - 57 161 67 */
.layout-page-mta .policy-type-card.policy-type-home:hover .card-body.clickable,
.layout-page-mta
  .policy-type-card.policy-type-home
  .card-footer.clickable:hover,
.layout-page-mta .policy-type-card.policy-type-home:hover .rounded-icon span {
  /*background-color: rgb(57 161 67 / 15%);*/
}

.layout-page-mta .card.policy-type-card:hover .icon-policytype-home {
  color: rgb(57 161 67 / 100%);
}

/* PUBLIC LIABILITY: #5D2C86 - 93,44,134 */
.layout-page-mta .policy-type-card.policy-type-pl:hover .card-body.clickable,
.layout-page-mta .policy-type-card.policy-type-pl .card-footer.clickable:hover,
.layout-page-mta .policy-type-card.policy-type-pl:hover .rounded-icon span {
  /*background-color: rgb(93 44 134 / 15%);*/
}

.layout-page-mta .card.policy-type-card:hover .icon-policytype-pl {
  color: rgb(93 44 134 / 100%);
}

/* GAP: #AB1F1F - 171,31,31 */
.layout-page-mta .policy-type-card.policy-type-gap:hover .card-body.clickable,
.layout-page-mta .policy-type-card.policy-type-gap .card-footer.clickable:hover,
.layout-page-mta .policy-type-card.policy-type-gap:hover .rounded-icon span {
  /*background-color: rgb(171 31 31 / 15%);*/
}

.layout-page-mta .card.policy-type-card:hover .icon-policytype-gap {
  color: rgb(171 31 31 / 100%);
}
/* PET: #F28429 - 242,132,41 */
.layout-page-mta .policy-type-card.policy-type-pet:hover .card-body.clickable,
.layout-page-mta .policy-type-card.policy-type-pet .card-footer.clickable:hover,
.layout-page-mta .policy-type-card.policy-type-pet:hover .rounded-icon span {
  /*background-color: rgb(242 132 41 / 15%);*/
}

.layout-page-mta .card.policy-type-card:hover .icon-policytype-pet {
  color: rgb(242 132 41 / 100%);
}
/* TRADE: #ccff00 - 204,255,0 */
.layout-page-mta .policy-type-card.policy-type-trade:hover .card-body.clickable,
.layout-page-mta
  .policy-type-card.policy-type-trade
  .card-footer.clickable:hover,
.layout-page-mta .policy-type-card.policy-type-trade:hover .rounded-icon span {
  /*background-color: rgb(204 255 0 / 15%);*/
  /*background-color: #66666680;*/
}

.layout-page-mta .card.policy-type-card:hover .icon-policytype-trade {
  color: rgb(204 255 0 / 100%);
}

.layout-page-mta .policy-tier-card {
  border: 0;
}

.layout-page-mta .policy-tier-card img {
  max-height: 30px;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
  .layout-page-mta .policy-type-card:before {
    font-size: 8rem;
  }
}
/* ### Tablet - MD ### */
@media (min-width: 768px) {
  .layout-page-mta .policy-tier-card img {
    max-height: 40px;
  }
}
/* ### Desktop - LG ### */
@media (min-width: 992px) {
  .layout-page-mta .card-lg-shadow {
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 50%);
  }
}
/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
  .layout-page-mta .policy-type-card .card-title {
    font-size: 1.2rem !important;
  }
}
/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
