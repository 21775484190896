.layout-page-mta .policy-details .policy-details-heading {
    font-weight: 700;
    border-bottom: 2px solid #ffc629;
    padding-bottom: 0.25rem;
    margin-bottom: 1.25rem;
}

.layout-page-mta .policy-details .policy-details-heading .heading-text {
    font-size: 1rem;
    font-weight: 700;
}

.layout-page-mta .policy-details .policy-details-heading .icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

.layout-page-mta .policy-details .policy-details-subheading {
    margin-bottom: 0.5rem;
}

.layout-page-mta .policy-details .policy-details-subheading .heading-text {
    font-size: 1rem;
    font-style: italic;
}


.layout-page-mta .policy-details .policy-details-content {
    margin-bottom: 1rem;
}

.layout-page-mta .policy-details .display-item {
    margin-bottom: 0.5rem;
}
.layout-page-mta .policy-details .display-item.new-value {
    margin-bottom: 0.75rem;
}

.layout-page-mta .policy-details .display-item.car-reg {
    margin-bottom: 0.5rem;
}

.layout-page-mta .policy-details .data-label {
    color: #7975be;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
}

.layout-page-mta .policy-details .data-label:not(.hz-mb) {
    margin-bottom: 0.5rem;
}

.layout-page-mta .policy-details .data-value {
    overflow-wrap: anywhere;
    font-size: 0.875rem;
}

.layout-page-mta .policy-details .data-value:not(.hz-mb) {
    margin-bottom: 1rem;
}

.layout-page-mta .policy-details .data-value:last-child {
    margin-bottom: 0;
}

.layout-page-mta .policy-details .new-value .data-value,
.layout-page-mta .policy-details .new-value .data-value>div {
    font-weight: 500;
}


/* ### Mobile - SM ### */
@media (min-width: 576px) {}

/* ### Tablet - MD ### */
@media (min-width: 768px) {

    .layout-page-mta .policy-details .policy-details-subheading {
        margin-bottom: 1rem;
    }

    .layout-page-mta .policy-details .policy-details-subheading .heading-text {
        font-size: 1.2rem;
    }

    .layout-page-mta .policy-details .data-label {
        font-size: 1rem;
    }

    .layout-page-mta .policy-details .data-value {
        font-size: 1rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {}