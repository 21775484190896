/* PAGE NAVIGATION */

.btn-renewal.btn-fb-loading {
  color: white;
  background-color: black;
  opacity: 1;
}


.btn-renewal {
    background-color: #7975be;
    border-color: #7975be;
    color: white;
    min-height: 0; /* #2821 */
    /* padding: 1rem 1.5rem; */
    /* font-weight: 500; */
}

    .btn-renewal:active,
    .btn-renewal.active {
        background-color: #5b588f;
        border-color: #7975be;
        color: white;
    }

    .btn-renewal:focus,
    .btn-renewal:focus-visible {
        background-color: #5b588f;
        border-color: #7975be;
        color: white;
        box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25);
    }

    .btn-renewal:hover {
        background-color: #5b588f;
        border-color: #7975be;
        color: white;
    }
    

.btn-outline-renewal,
.btn-check:focus .btn-outline-renewal {
    color: #7975be;
    border-color: #7975be;
    background-color: white;
    border-width: 1px; /* #2821 */
    border-style: solid;
}

    .btn-outline-renewal:active,
    .btn-check:active + .btn-outline-renewal,
    .btn-check:checked + .btn-outline-renewal,
    .btn-check:active + .btn-outline-primary,
    .btn-outline-renewal.active,
    .btn-outline-renewal.dropdown-toggle.show {
        background-color: #7975be;
        border-color: #7975be;
        color: white;
    }

        .btn-check:checked + .btn-outline-renewal:focus,
        .btn-check:active + .btn-outline-renewal:focus,
        .btn-outline-renewal:active:focus,
        .btn-outline-renewal.active:focus,
        .btn-outline-renewal.dropdown-toggle.show:focus,
        .btn-check:focus + .btn-outline-renewal,
        .btn-outline-renewal:focus-visible {
            box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25);
        }
        .btn-outline-renewal:focus-visible {
          color: #7975be;
      }
      .btn-outline-renewal.active:focus-visible {
        color: white;
    }

    .btn-outline-renewal:hover {
        background-color: #7975be;
        border-color: #7975be;
        color: white;
    }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
