/* ### DEFAULT VALUES - XS ### */
.panel.panel-renewal {
  border: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.panel.panel-renewal .panel-heading {
  background-color: transparent;
  border-bottom: none;
  padding-bottom: 0;
  padding-top: 0.75rem;
}
.panel.panel-renewal .panel-heading .text {
  font-weight: 600;
}
.panel.panel-renewal .info-box,
.panel.panel-renewal .ipt-statement .alert {
  border: none;
  background-color: #f0f0f0;
}

.panel.panel-renewal .panel-heading div:not(:last-child) {
  margin-right: 0.75rem;
}

.panel.panel-renewal .panel-heading .icon {
  color: #7975be;
}

.panel.panel-renewal .panel-heading .text {
  font-size: 1.25rem;
}

.panel.panel-renewal .panel-body {
  padding-top: 0.5rem;
}

.panel.panel-renewal .panel-body>div {
  margin-bottom: 0.5rem;
}

.panel.panel-renewal .panel-body .info-box {
  margin-top: 2rem;
  font-size: 0.875rem;
}
.panel.panel-renewal .panel-body .intro-text {
  margin-bottom: 1.5rem;
}
.panel.panel-renewal .panel-body .policy-details {
  margin-bottom: 2rem;
}


.panel.panel-renewal .inst-details {
  font-size: 0.875rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
  .panel.panel-renewal .panel-heading {
    padding-top: 1rem;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
  }

  .panel.panel-renewal .panel-body {
    padding-left: 2.2rem;
    padding-right: 2.2rem;
  }

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {}