/* ### DEFAULT VALUES - XS ### */
.renewal-directdebit .renewal-price-container .price .pound {
    font-size: 3.5rem;
}

.renewal-directdebit.layout-page .body-container {
    padding-top: 0;
  }

  .dd-img {
    max-height: 60px;
  }

  .dd-heading {
    font-weight: 600;
  }

  .renewal-directdebit.layout-page .fb-form-group {
    margin-bottom: 1rem;
  }
  .renewal-directdebit.layout-page .fb-form-label {
    font-weight: 500;
    font-size: 1rem;
  }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
