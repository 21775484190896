/* ### DEFAULT VALUES - XS ### */

.notifications {
}
    .notifications .list-group {
    }

    .notifications .list-group-item {
        background-color: transparent;
        border: none;
    }

        .notifications .list-group-item.is-unread {
            font-weight: 500;
        }


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
