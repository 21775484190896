/* ### DEFAULT VALUES - XS ### */

.card-fb {
    border: 1px solid #999;
    border-radius: 10px;
}
    .card-fb > .card-header {
        /*background-color: transparent;*/
        padding: 1rem 0.5rem;
    }
        .card-fb > .card-header .icon:not(.badge-icon) {
            font-size: 3rem;
            margin-right: 2rem;
        }

    .card-fb > .card-body {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .card-fb > .card-footer {
        background-color: transparent;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

.card-fb.no-border {
    border: none;
}
    .card-fb.no-border > .card-header {
        border-bottom: none;
    }

    .card-fb.no-border > .card-body {
    }

    .card-fb.no-border > .card-footer {
        border: none;
    }

    /* CARD HEADER WITH POLICY STATUS */
    .card-fb > .card-header:has(.header-with-status) {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
    .card-fb > .card-header .header-with-status {
        
    }
        .card-fb > .card-header .header-with-status .policy-badge {
            font-size: 0.875rem;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0.25rem;
        }
            .card-fb > .card-header .header-with-status .policy-badge .icon {
                font-size: 0.875rem;
            }
        .card-fb > .card-header .header-with-status .vehicle-registration {
            font-size: 1.5rem;
        }
        .card-fb > .card-header .header-with-status .description {
            font-size: 1rem;
            padding-top: 0.5rem;
            text-align: center;
        }


/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .card-fb > .card-header {
        /*background-color: transparent;*/
        padding: 1rem 1.5rem;
    }

    .card-fb > .card-body {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .card-fb > .card-footer {
        background-color: transparent;
        padding-left: 1rem;
        padding-right: 1rem;
    }


    /* CARD HEADER WITH POLICY STATUS */
    .card-fb > .card-header:has(.header-with-status) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .card-fb > .card-header .header-with-status {
    }

        .card-fb > .card-header .header-with-status .policy-badge {
            font-size: 1rem;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
        }

            .card-fb > .card-header .header-with-status .policy-badge .icon {
                font-size: 1.25rem;
            }

        .card-fb > .card-header .header-with-status .vehicle-registration {
            font-size: 1.9rem;
        }

        .card-fb > .card-header .header-with-status .description {
            padding-left: 1.25rem;
            font-size: 1.2rem;
            padding-top: 0;
            text-align: left;
        }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
    /* Side panel - not visible below LG */
    .card.side-content {
        border: none;
    }

        .card.side-content .card-body {
            padding: 1.5rem;
        }

            .card.side-content .card-body > div {
                margin-bottom: 1.25rem;
            }

                .card.side-content .card-body > div:first-of-type {
                    font-weight: 500;
                }

                .card.side-content .card-body > div:last-of-type {
                    font-weight: 500;
                }

        .card.side-content .feature > div:first-of-type {
            width: 40px;
        }
        .card.side-content .feature .text {
            font-size: 0.875rem;
        }

        .card.side-content .icon {
            font-size: 1.5rem;
        }
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

