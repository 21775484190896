/* ### DEFAULT VALUES - XS ### */
.debug-info {
    /* display: none; */
    /*background-color: khaki;*/
}

    .debug-info span {
        overflow-wrap: anywhere;
    }
    .debug-info:has(> div) {
        padding: 3rem;
    }

.dev-container {
    /* display: none; */
}

.debug-resolution-finder {
    /*display: none;*/
    font-weight: bold;
    color: red;
}

.lctr-pager-route-buttons {
    margin-top: 1rem;
    /* display: none; */
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .debug-info .row {
        /* margin-left: -15px;
        margin-right: -15px; */
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
