.layout-page-renewal .vehicle-container .btn-search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout-page-renewal .vehicle-container .search .btn-link {
  padding: 0;
}
.layout-page-renewal .vehicle-container .search .btn-fb {
  /* padding: 0; */
  padding-left: 40px;
  padding-right: 40px;
  /* padding-top: 1px;
	padding-bottom: 1px; */
  /* height: 100%; */
}

.layout-page-renewal .vehicle-container .found .header {
  /* font-weight: 700;
	font-size: 1.2rem; */
}

.layout-page-renewal .vehicle-container .found .details {
  border: #ccc 1px solid;
  border-radius: 5px;
  padding: 25px;
}

.layout-page-renewal .vehicle-container .found .vehicle-registration {
  padding: 0rem 0.7rem;
  font-size: 1.9em;
  letter-spacing: 1.5px;
  border: 2px solid #000000 !important;
  color: #000000;
  /* background-image: linear-gradient(to bottom, #ffc439 0, #f4bc37 100%); */
  font-family: Charles Wright;
  border-radius: 0.375rem;
  background-color: #efefef;
}

.layout-page-renewal
  .vehicle-container
  .found
  input.vehicle-registration::placeholder {
  color: #a1a1a1;
}

.layout-page-renewal .vehicle-container .found span.vehicle-registration {
  width: 165px;
}

.layout-page-renewal .vehicle-container .found .no-registration {
  margin-bottom: 0;
}
.layout-page-renewal .vehicle-container .found .no-registration .btn-link {
  padding-left: 0;
  padding-right: 0;
}

.layout-page-renewal .alert.no-registration .btn-link {
  padding: 0;
  vertical-align: baseline;
}

.layout-page-renewal .vehicle-container .found .btn-link {
  padding-bottom: 0;
  padding-top: 0;
}

.layout-page-renewal .vehicle-container .vehicle-edit-confirm-button {
  /* background-color: #7975be;
	border-color: #7975be;
	color: white; */
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
  .layout-page-renewal .vehicle-container .btn-search {
    width: 200px;
  }
}
