/* ### DEFAULT VALUES - XS ### */

.layout-page-mta .fsg-btn-group {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.layout-page-mta .fsg-btn-group > .btn,
.layout-page-mta .fsg-btn-group > .btn-group {
  width: 100%;
  padding: 1rem 0 1rem 0;
}

/*.layout-page-mta .fsg-btn-group .fb-radio-btn {
  color: #000;
  border-color: #ccc;
}

.layout-page-mta .fsg-btn-group .fb-radio-btn:hover {
  color: #fff;
  background-color: #5b588f;
}*/

.layout-page-mta .fsg-btn-group .btn-check:focus + .fb-radio-btn,
.layout-page-mta .fsg-btn-group .fb-radio-btn:focus,
.layout-page-mta .fsg-btn-group .fb-radio-btn:focus-within {
  border-color: #7975be;
  box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25);
  z-index: 100;
}

/* General button groups */
.layout-page-mta
  .fsg-btn-group:not(.yes-no)
  > .btn:not(:last-child):not(.dropdown-toggle),
.layout-page-mta
  .fsg-btn-group:not(.yes-no)
  > .btn-group:not(:last-child)
  > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.layout-page-mta .fsg-btn-group:not(.yes-no) > .btn:not(:first-child),
.layout-page-mta .fsg-btn-group:not(.yes-no) > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.layout-page-mta .fsg-btn-group:not(.yes-no) > .btn ~ .btn,
.layout-page-mta
  .fsg-btn-group:not(.yes-no)
  > .btn-group:not(:first-child)
  > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* Overrides for Yes/No */
.layout-page-mta .fsg-btn-group.yes-no {
  flex-direction: row;
}

.layout-page-mta .fsg-btn-group.yes-no .btn {
  width: auto;
  min-width: 50%;
}

.layout-page-mta .fsg-btn-group.yes-no > .btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}

.layout-page-mta .fsg-btn-group.yes-no > .btn:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
  .layout-page-mta .fsg-btn-group.volxs {
    flex-direction: row;
    display: inline-flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
  }

  .layout-page-mta .fsg-btn-group.volxs > .btn,
  .layout-page-mta .fsg-btn-group.volxs > .btn-group {
    position: relative;
    flex: 1 1 auto;
  }

  .layout-page-mta
    .fsg-btn-group.volxs
    > .btn:not(:last-child):not(.dropdown-toggle):nth-of-type(1),
  .layout-page-mta .fsg-btn-group.volxs > .btn:nth-of-type(1) {
    border-bottom-left-radius: 0.25rem;
  }

  .layout-page-mta .fsg-btn-group.volxs > .btn,
  .layout-page-mta .fsg-btn-group.volxs > .btn-group {
    padding: 0.925rem 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
  }

  .layout-page-mta
    .fsg-btn-group.volxs
    > .btn:not(:last-child):not(.dropdown-toggle),
  .layout-page-mta .fsg-btn-group.volxs > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .layout-page-mta .fsg-btn-group.volxs > .btn:nth-child(n + 3),
  .layout-page-mta .fsg-btn-group.volxs > :not(.btn-check) + .btn,
  .layout-page-mta .fsg-btn-group.volxs > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .layout-page-mta .fsg-btn-group.volxs > .btn:not(:first-child),
  .layout-page-mta .fsg-btn-group.volxs > .btn-group:not(:first-child) {
    margin-top: 0;
    margin-left: -1px;
  }

  .layout-page-mta .fsg-btn-group.volxs > .btn ~ .btn,
  .layout-page-mta .fsg-btn-group.volxs > .btn-group:not(:first-child) > .btn {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
  .layout-page-mta .fsg-btn-group {
    flex-direction: row;
    display: inline-flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
  }

  .layout-page-mta .fsg-btn-group > .btn,
  .layout-page-mta .fsg-btn-group > .btn-group {
    position: relative;
    flex: 1 1 auto;
  }

  .layout-page-mta
    .fsg-btn-group:not(.yes-no)
    > .btn:not(:last-child):not(.dropdown-toggle):nth-of-type(1),
  .layout-page-mta .fsg-btn-group > .btn:nth-of-type(1) {
    border-bottom-left-radius: 0.25rem;
  }

  .layout-page-mta .fsg-btn-group:not(.yes-no) > .btn,
  .layout-page-mta .fsg-btn-group > .btn-group {
    padding: 0.925rem 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
  }

  .layout-page-mta
    .fsg-btn-group:not(.yes-no)
    > .btn:not(:last-child):not(.dropdown-toggle),
  .layout-page-mta
    .fsg-btn-group:not(.yes-no)
    > .btn-group:not(:last-child)
    > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .layout-page-mta .fsg-btn-group:not(.yes-no) > .btn:nth-child(n + 3),
  .layout-page-mta .fsg-btn-group:not(.yes-no) > :not(.btn-check) + .btn,
  .layout-page-mta
    .fsg-btn-group:not(.yes-no)
    > .btn-group:not(:first-child)
    > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .layout-page-mta .fsg-btn-group:not(.yes-no) > .btn:not(:first-child),
  .layout-page-mta .fsg-btn-group:not(.yes-no) > .btn-group:not(:first-child) {
    margin-top: 0;
    margin-left: -1px;
  }

  .layout-page-mta .fsg-btn-group:not(.yes-no) > .btn ~ .btn,
  .layout-page-mta
    .fsg-btn-group:not(.yes-no)
    > .btn-group:not(:first-child)
    > .btn {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .layout-page-mta .fsg-btn-group.yes-no {
    max-width: 60%;
  }

  .layout-page-mta .fsg-btn-group.yes-no .btn {
  }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
