/* ### DEFAULT VALUES - XS ### */

/* This will allow text to wrap that wouldn't normally, e.g. a long email address */
.overflow-wrap-unset {
    overflow-wrap: unset;
}

.overflow-wrap-normal {
    overflow-wrap: normal;
}

.whitespace-no-wrap {
    white-space: nowrap;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}


/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
