/* ### DEFAULT VALUES - XS ### */
.opex {
    margin-bottom: 1rem;
}
	.opex .opex-icon {
		font-size: 2rem;
		color: #7975be;	
	}
    .opex .opex-title {
        font-weight: 500;
    }

.opex .opex-read-more {
	font-size: 0.875rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
	
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
	
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
	
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
	
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
