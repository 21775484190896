/* ### DEFAULT VALUES - XS ### */

/*  * {
  color: green;
} */

 .btn-link {
  color: #7975be;
  text-decoration: none;
}

 .btn-link:hover {
  text-decoration: underline;
}

 .btn-link-inline {
  display: inline;
  padding: 0;
  vertical-align: baseline;
}

 .btn svg {
  vertical-align: sub;
}

 .btn-fb {
  background-color: #ffc629;
  border-color: #ffc629;
  color: black;
}
 .btn-fb:not(.btn-sm) {
  padding: 0.5rem 1.5rem;
  min-height: 2.75rem;
}

 .btn-fb.disabled:focus,
 .btn-fb.disabled:hover,
 .btn-fb.disabled:focus-visible {
  background-color: #dcdcdc;
}

 .btn-fb:not(.disabled):not(.btn-outline-fb):active,
 .btn-fb:not(.disabled):not(.btn-outline-fb):hover,
 .btn-fb:not(.disabled):not(.btn-outline-fb):focus-visible {
  background-color: black;
  border-color: black;
  color: white;
}

 .btn-fb.btn-fb-loading {
  color: white;
  background-color: black;
  opacity: 1;
}

 .btn-outline-fb,
 .btn-check:focus + .btn-outline-fb {
  color: #303030;
  background-color: #f5f5f5;
  border: none;
}

 .btn-outline-fb:active,
 .btn-outline-fb:hover,
 .btn-outline-fb:focus,
 .btn-check:active + .btn-outline-fb,
 .btn-check:checked + .btn-outline-fb,
 .btn-check:active + .btn-outline-primary,
 .btn-outline-fb.active,
 .btn-outline-fb.dropdown-toggle.show {
  background-color: #dcdcdc;
}

 .btn-check:checked + .btn-outline-fb:focus,
 .btn-check:active + .btn-outline-fb:focus,
 .btn-outline-fb:active:focus,
 .btn-outline-fb.active:focus,
 .btn-outline-fb.dropdown-toggle.show:focus,
 .btn-check:focus + .btn-outline-fb {
  box-shadow: 0 0 0 0.2rem rgba(58, 5, 138, 0.25);
}

 .btn-link-fb {
  box-shadow: none !important;
  text-decoration: none;
}

 .btn-link-fb:hover span {
  text-decoration: underline;
}

 .btn-link-fb:active span {
  text-decoration: underline;
}

 .btn-link-fb:focus span {
  text-decoration: underline;
}

 button.btn:disabled {
  background-color: #333;
  color: white;
}

 button:disabled:hover {
  color: white;
}

 .btn-loading {
  min-height: 40px;
  padding: 0 var(--bs-btn-padding-x);
}

 .btn-toggle-fb {
}

 .btn-toggle-fb.active {
  background-color: #7975be;
  color: white;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
