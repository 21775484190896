/* ### DEFAULT VALUES - XS ### */

.layout-page-mta .panel {
  border-radius: 5px;
  border-style: solid;
  border-color: transparent;
}
.layout-page-mta .panel > .card-header {
  padding-top: 2.5rem;
  padding-bottom: 0;
  padding-top: 1.5rem;
  border-style: none;
  background-color: transparent;
}

.layout-page-mta .panel > .card-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.layout-page-mta .panel .info-box,
.layout-page-mta .panel .ipt-statement .alert {
  border: none;
  background-color: #f0f0f0;
}

.layout-page-mta .panel-border {
  border: none;
  border-radius: initial;
  border-bottom-style: solid;
}

.layout-page-mta .panel-border:first-child {
  border-top-style: solid;
}

.layout-page-mta .panel-border.modal-panel {
  border-radius: 5px;
  border-style: solid;
  border-color: #ccc;
}

.layout-page-mta .panel-border.modal-panel > .card-header {
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
}

.layout-page-mta .panel.mta-changes-summary > .card-body {
  padding-top: 1rem;
}

.panel.mta-changes-summary .panel-body .intro-text {
  margin-bottom: 1.5rem;
}
.panel.mta-changes-summary .panel-body .policy-details {
  margin-bottom: 2rem;
}


/* ### Mobile - SM ### */
@media (min-width: 576px) {
  .layout-page-mta .panel-border {
    border-style: solid;
    border-radius: 5px;
    border-color: #ccc;
  }

  .layout-page-mta .panel-border > .card-header {
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;
  }

  .layout-page-mta .panel-border:first-child {
    /*border-top-width: 2px;*/
  }
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
  .layout-page-mta .panel > .card-header {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .layout-page-mta .panel > .card-body {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .layout-page-mta .panel.ii-details-confirm > .card-header,
  .layout-page-mta .panel.direct-debit > .card-header {
    padding: 1.5rem 2.5rem 0 2.5rem;
  }
  .layout-page-mta .panel.ii-details-confirm > .card-body {
    padding: 0;
  }
  .layout-page-mta .panel.ii-details-confirm > .card-body .card > .card-header {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .layout-page-mta .panel.ii-details-confirm > .card-body .card > .card-body {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .layout-page-mta .panel.ii-details-confirm .endo {
  }
  .layout-page-mta .panel.ii-details-confirm .endo .precis-heading {
    font-weight: bold;
    color: #7975be;
    margin-bottom: 0.5rem;
  }
  .layout-page-mta .panel.ii-details-confirm .endo .precis-heading:not(:first-child) {
    margin-top: 0.5rem;
  }

  .layout-page-mta .panel.ii-details-confirm .endo .precis-text {
    margin-bottom: 0.5rem;
  }

  .layout-page-mta .panel.ii-misc > .card-header {
    padding: 1.5rem 2.5rem 0 2.5rem;
  }
  .layout-page-mta .panel.ii-misc > .card-body {
    padding-top: 0;
  }
  .layout-page-mta .panel.ii-misc > .card-body .card > .card-header {
    padding-left: 0;
    padding-right: 0;
  }

  .layout-page-mta .panel.ii-misc > .card-body .card > .card-body {
    padding-left: 0;
    padding-right: 0;
  }

}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
