/* ### DEFAULT VALUES - XS ### */

.site-alerts .alert-primary {
    color: black;
    background-color: white;
    border-color: #ffc629;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
