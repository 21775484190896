/* ### DEFAULT VALUES - XS ### */

.layout-page {
  margin-bottom: 4rem;
}

.layout-page .header-sticky-inner {
  background-color: #fff3df;
  z-index: 99999;
}

.layout-page .header-container:not(.header-container-sticky) {
  padding-top: 3rem;
  padding-bottom: 2rem;
  border-top: 3px solid #ffc629;
}

.layout-page .header-container.header-container-sticky {
  padding-top: 3rem;
  padding-bottom: 2rem;
  border-top: 3px solid #ffc629;
}

.layout-page .header-sticky-active .header-container.header-container-sticky {
  padding-top: 0;
  border-top: 0;
  padding-bottom: 1rem;
}

.layout-page .header-container .header-text {
  font-weight: 700;
  font-size: 2rem;
}
.layout-page .header-container .description-text p:last-of-type {
  margin-bottom: 2rem;
}

.layout-page .header-container a:hover {
  text-decoration: none;
}

.layout-page .body-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: white;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
