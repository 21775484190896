.layout-page-renewal .renewal-start-section:not(:last-child) {
    border-bottom: 1px solid #999;
}

.layout-page-renewal .renewal-start-section.disabled {
    border-radius: 0.2rem;
    /* background-color: #ccc !important; */
}

    .layout-page-renewal .renewal-start-section.disabled * {
        color: #ccc !important;
    }

.layout-page-renewal .renewal-change .renewal-set {
    border: 1px solid #666;
    padding: 1rem;
    border-radius: 1rem;
}

    .layout-page-renewal.renewal-change .renewal-set .renewal-set-missing-msg {
        color: green;
    }

    .layout-page-renewal.renewal-change .renewal-set .renewal-list-item {
        border: 1px solid #666;
        padding: 1rem;
        border-radius: 1rem;
    }

    .layout-page-renewal.renewal-change .renewal-set .renewal-set-button-container {
        border: 1px solid #666;
        padding: 1rem;
        border-radius: 1rem;
    }

.layout-page-renewal.renewal-change .renewal-heading {
    font-weight: 700;
}

.layout-page-renewal.renewal-change
.renewal-item-additional-questions
.renewal-item-additional-questions-heading {
    font-weight: 600;
    font-size: 1rem;
    color: #292e39;
}

.layout-page-renewal .renewal-change-list .renewal-change-item-container {
    padding-bottom: 1rem;
}

    .layout-page-renewal .renewal-change-list .renewal-change-item-container:not(:first-child) {
        padding-top: 1rem;
    }

    .layout-page-renewal .renewal-change-list .renewal-change-item-container:not(:last-child) {
        border-bottom: 1px solid #999;
    }

.layout-page-renewal .renewal-change-item-box {
    border: 2px solid #7975be;
    border-radius: 1rem;
}
