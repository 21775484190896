/* ### DEFAULT VALUES - XS ### */
.renewal-price-container .price {
    font-size: 1.25rem;
    font-weight: 700;
}
.renewal-price-container .price span {
    font-weight: 700;
}
.renewal-price-container .price .pound {
    font-size: 4.5rem;
    font-weight: 900;
}
.renewal-price-container .price-info {
    color: #000000;
    text-align: center;
    padding: 0.25rem 0;
    font-size: 0.8rem;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
