.link-white,
.btn.link-white {
    color: white;
    text-decoration: none;
}
    .link-white:hover,
    .btn.link-white:hover {
        color: #ffe6be;
        text-decoration: none;
        transition: color .3s;
    }

.link-black,
.btn.link-black {
    color: black;
    text-decoration: none;
}
    .link-black:hover,
    .btn.link-black:hover {
        color: #7975be;
        text-decoration: none;
        transition: color .3s;
    }
    .link-black.hover-inline:hover,
    .btn.link-black.hover-inline:hover {
        text-decoration: underline;
        transition: none;
    }

.link-purple,
.btn.link-purple {
    color: #7975be;
    text-decoration: none;
}
.btn.link-purple.disabled {
    color: #6c757d;
}
    .link-purple:hover,
    .btn.link-purple:hover {
        color: #c5b4e3;
        text-decoration: none;
        transition: color .3s;
    }
    .link-purple.hover-inline:hover,
    .btn.link-purple.hover-inline:hover {
        color: #7975be;
        text-decoration: underline;
        transition: none;
    }
