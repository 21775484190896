/* ### DEFAULT VALUES - XS ### */

/* .fb-header {
  margin-bottom: 0.75rem;
  font-weight: 700;
  font-size: 18px;
  color: #292e39;
} */

.fb-list-headers * {
    color: #7975be;
    font-weight: 700;
    text-transform: uppercase;
}

.fb-list-header {
  color: #7975be;
  font-weight: 700;
  text-transform: uppercase;
}

.bi-fb.bi-circle-fill {
  color: #ffc629;
  font-size: 0.6rem;
}

.fb-text-info {
  color: #7975be;
}

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    /* .fb-header {
        font-size: 1.2rem;
    } */
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {    
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}

/* ### Extra Large Desktop - XXL ### */
@media (min-width: 1400px) {
}
