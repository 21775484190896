/* ### MODAL VALUES - XS ### */

/* https://reactcommunity.org/react-modal/styles/ */
.modal-faded-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background-color: #ccc;
    opacity: 0.8;
}

.modal-solid-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background-color: #fff3df;
}

.modal-transparent-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    opacity: 0;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}

.modal-main {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    max-width: 600px;
}
    .modal-main .card-fb .card-body p:last-of-type {
        margin-bottom: 0;
    }

    .modal-main .card-fb .card-footer {
        padding: 1rem;
    }
    .modal-main .card-fb .card-footer .order-1 {
        margin-bottom: 0.5rem;
    }

/*
	************************************
	modal disabled
	************************************
*/

.modal-disabled {
}

    .modal-disabled .radio-toggle {
        border-color: dimgrey;
        color: dimgrey;
    }

        .modal-disabled .radio-toggle:hover {
            background-color: white;
            color: dimgrey;
        }

        .modal-disabled .radio-toggle:focus-within,
        .modal-disabled .radio-toggle .form-check-input:focus {
            box-shadow: none;
        }

        .modal-disabled .radio-toggle .form-check-input:checked {
            background-color: dimgrey;
            border-color: dimgrey;
        }

    .modal-disabled .btn-outline-fb {
        border-color: dimgrey;
        color: dimgrey;
    }

        .modal-disabled .btn-outline-fb:hover {
            background-color: white;
            color: dimgrey;
        }

        .modal-disabled .btn-outline-fb:active,
        .modal-disabled .btn-check:checked + .btn-outline-fb,
        .modal-disabled .btn-check:active + .btn-outline-primary,
        .modal-disabled .btn-outline-fb.active,
        .modal-disabled .btn-outline-fb.dropdown-toggle.show {
            background-color: dimgrey;
            color: white;
            box-shadow: none;
        }

    .modal-disabled .btn-check:focus + .btn-outline-fb {
        box-shadow: none;
    }

    .modal-disabled .btn-fb,
    .modal-disabled .btn-fb:focus {
        border-color: dimgrey;
        background-color: dimgrey;
        color: white;
        box-shadow: none;
    }

        .modal-disabled .btn-fb:hover {
            background-color: dimgrey;
        }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
    .modal-main.date-change {
        max-width: 500px;
    }

    .modal-main .card-fb .card-footer .order-1 {
        margin-bottom: 0;
    }
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {
    .modal-main .card-fb .card-body {
        padding: 2rem 2rem 0 2rem;
    }

    .modal-main .card-fb .card-footer {
        padding: 2rem 2.5rem;
    }
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
