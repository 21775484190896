.layout-page-mta .heading-main {
  font-weight: 600;
  font-size: 1.2rem;
  color: #292e39;
}

.layout-page-mta .heading-secondary {
  font-weight: 600;
  font-size: 1.0rem;
  color: #292e39;
}

.layout-page-mta .heading-sub {
  color: #7975be;
  font-size: 1.2rem;
}
