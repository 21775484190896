/* ### DEFAULT VALUES - XS ### */

.policy-badge.badge {
    background-color: transparent;    
    color: black;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
}
    .policy-badge.badge .prefix {
        margin-right: 4px;
    }

    .policy-badge.badge .icon {
        margin-left: 0.5rem;
        font-size: 1.75rem;
    }

        .policy-badge.badge .icon.bi-info-circle-fill {
            color: dodgerblue;
        }

/* ### Mobile - SM ### */
@media (min-width: 576px) {
}

/* ### Tablet - MD ### */
@media (min-width: 768px) {    
}

/* ### Desktop - LG ### */
@media (min-width: 992px) {
}

/* ### Large Desktop - XL ### */
@media (min-width: 1200px) {
}
